import React, { useEffect, useState, useRef } from "react"
import { RichText, RichTextBlock } from "prismic-reactjs"
import ReactPlayer from "react-player/vimeo"
import { Waypoint } from "react-waypoint"
import { useMediaQuery } from "react-responsive"

import Layout from "../components/layout"
import ProjectSlider from "../components/project-slider"
import ContentBlock from "../components/content-block"
import VideoBlock from "../components/video-block"
import ImageBlock from "../components/image-block"
import { useOnLoadImages } from "../hooks/useOnLoadImages"
import Preloader from "../components/preloader"

interface ProjectPageProps {
  url: string
  page_title: string
  top_slider: {
    slide: { url: string; alt: string }
  }[]
  featured_video: string
  slices: any
  description: RichTextBlock[]
}

const ProjectPage = props => {
  const pageProps: ProjectPageProps = props.pageContext
  const { url, page_title, top_slider, slices, description, featured_video } =
    pageProps
  const wrapperRef = useRef<HTMLDivElement>(null)
  let imagesLoaded = useOnLoadImages(wrapperRef)

  useEffect(() => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "page_view", {
        page_title: url,
        page_path: "/" + url,
        send_to: "G-LB7ZYCKNWC",
      })
    }
  }, [])

  //used to count the blocks that get split into 2 cols on large screens
  //so we can alternate the order of image/text -> text/image
  let blockCounter = 0

  const isLgScreen = useMediaQuery({ query: "(min-width: 1024px)" })
  const [isPlaying, setIsPlaying] = useState(false)

  const onEnterView = () => {
    setIsPlaying(true)
  }

  const onExitView = () => {
    setIsPlaying(false)
  }

  let projectFeature = (
    <ProjectSlider
      images={top_slider.map(item => {
        return { alt: item.slide.alt, url: item.slide.url }
      })}
    />
  )

  if (featured_video) {
    projectFeature = (
      <Waypoint onEnter={onEnterView} onLeave={onExitView}>
        <div className="items-center justify-center flex-1 bg-black">
          <div
            className="relative w-full h-full"
            style={{ paddingTop: "56.25%" }}
          >
            <ReactPlayer
              className="absolute inset-0"
              playing={isPlaying}
              controls={!isLgScreen}
              volume={0}
              muted={true}
              width="100%"
              height="100%"
              loop
              url={featured_video}
            />
          </div>
        </div>
      </Waypoint>
    )
  }
  return (
    <>
      {!imagesLoaded && <Preloader />}

      <Layout>
        <div ref={wrapperRef}>
          {projectFeature}
          <div className="pt-8 pb-4 text-2xl font-semibold text-center xl:text-4xl">
            {page_title}
          </div>
          <div className="px-8 pb-8 mx-auto text-base leading-normal text-justify sm:px-10 md:px-6 lg:px-20 xl:text-lg xl:max-w-6/10 xl:px-0 md:pb-6">
            <RichText render={description} />
          </div>
          {slices.map((slice, i) => {
            if (slice.slice_type === "content_block") {
              blockCounter++
              return (
                <ContentBlock
                  key={i}
                  header={slice.primary.header.text}
                  text={slice.primary.text.richText}
                  imageUrl={slice.primary.image.url}
                  imageWidth={slice.primary.image_width}
                  rowReversed={blockCounter % 2 != 0}
                  justifyText
                />
              )
            } else if (slice.slice_type === "video_block") {
              blockCounter++
              return (
                <VideoBlock
                  key={i}
                  header={slice.primary.header.text}
                  text={slice.primary.text.richText}
                  video={slice.primary.video.embed_url}
                  rowReversed={blockCounter % 2 != 0}
                  justifyText
                />
              )
            } else if (slice.slice_type === "image_slider") {
              const _images = slice.items.map(item => {
                return { url: item.image.url, alt: item.image.alt }
              })
              return (
                <div key={i}>
                  <ProjectSlider images={_images} />
                </div>
              )
            } else if (slice.slice_type === "image") {
              return (
                <div key={i} className="my-4">
                  <ImageBlock
                    imageUrl={slice.primary.image.url}
                    width={slice.primary.width}
                    alt={slice.primary.image.alt}
                  />
                </div>
              )
            } else return null
          })}
        </div>
      </Layout>
    </>
  )
}

export default ProjectPage
