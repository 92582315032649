import React, { CSSProperties } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"

interface ProjectSliderProps {
  images: { url: string; alt: string }[]
}

const indicatorWrapper: CSSProperties = {
  display: "inline-block",
  padding: "3px",
  margin: "0 12px 10px 12px",
  transformOrigin: "50% 60%",
}

const indicatorStyles: CSSProperties = {
  background: "#fff",
  width: 4,
  height: 4,
  display: "inline-block",
  borderRadius: "20px",
}

const ProjectSlider: React.FC<ProjectSliderProps> = ({ images }) => {
  return (
    <Carousel
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (images.length == 1) return null
        if (isSelected) {
          return (
            <li
              className="duration-200 ease-in-out transform scale-300"
              style={indicatorWrapper}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            >
              <span style={{ ...indicatorStyles }} />
            </li>
          )
        }
        return (
          <li
            className="duration-200 ease-in-out transform hover:scale-250 "
            style={indicatorWrapper}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          >
            <span style={indicatorStyles} />
          </li>
        )
      }}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            className="absolute left-0 z-10 ml-6 transform -translate-y-1/2 focus:outline-none opacity-1 top-1/2"
            onClick={onClickHandler}
            title={label}
          >
            <i className="arrow left md:p-3" />
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            className="absolute right-0 z-10 mr-6 transform -translate-y-1/2 focus:outline-none opacity-1 top-1/2"
            onClick={onClickHandler}
            title={label}
          >
            <i className="arrow right md:p-3" />
          </button>
        )
      }
    >
      {images.map((image, i) => {
        return (
          <div key={i} className="h-full max-h-360 md:max-h-600">
            <img
              className="object-cover h-full min-h-300 md:min-h-600"
              src={image.url}
              alt={image.alt}
            />
          </div>
        )
      })}
    </Carousel>
  )
}

export default ProjectSlider
